import ProductDisplaySetting from "@/entities/product-display-setting";
import ProductDisplayService, {containsWater, INormalizedItem} from "@/services/product-display-service";
import uniq from "lodash/uniq";
import ProductEntity from "@/entities/product-entity";
import ProductDisplayServiceMadeInArea from "@/services/product-display-service-made-in-area";
import ProductDisplayServiceAdditive, {isThickeningStabilizer} from "@/services/product-display-service-additive";
import {AdditivePurposeColoring} from "@/repositories/master/additive-repository";
import {CompanySettingEntity} from "@/entities/company-setting-entity";

export default class ProductDisplayServiceSettingVisibility {
  private madeInAreaService!:ProductDisplayServiceMadeInArea;
  private setting!:ProductDisplaySetting;

  public constructor(
    private readonly product: ProductEntity,
    private readonly productDisplayService: ProductDisplayService,
    public readonly companySetting: CompanySettingEntity,
  ) {
    this.setting = this.product.productDisplaySetting;

    this.madeInAreaService = new ProductDisplayServiceMadeInArea(product, this.setting, this.companySetting);
  }

  private getAllNormalizeItemsFlatten(): INormalizedItem[] {
    const items = ProductDisplayService.normalize(this.product, this.setting);

    const flatten = (items:INormalizedItem[]) => {
      return items.flatMap(item => flatten(item.items).concat(item));
    };

    return flatten(items);
  }

  // アレルゲンが一つ以上あるか
  public get showAllergenSummarized() {
    return this.product.getAllAllergens().length > 0;
  }

  public get showOnlyMinGmosFlag() {
    return this.product.getAllGmos().length > 0;
  }

  // アレルゲン個別表記 & 重複するアレルゲンが存在する
  public get showRepeatedAllergenOmitted() {
    if (this.setting.isAllergenSummarized) return false; //一括表記
    const allergens = this.product.getAllAllergens();
    return uniq(allergens.map(a => a.name)).length !== allergens.length;
  }

  // 複合原材料において3種類以上の原材料があり、3位以下で(その原材料内において)5％未満のものが存在する
  public get showManyCompositeItemsOmitted() {
    const items = this.getAllNormalizeItemsFlatten();
    return items.some(item => {
      const sortedInnerItems = ProductDisplayService.excludeFromIngredientNamesRecursive(item.items, this.setting.isWaterOmitted)
        .sort((a, b) => b.amountRatioInTheDirectParent - a.amountRatioInTheDirectParent);
      return sortedInnerItems.length >= 3 &&
        sortedInnerItems.splice(2).some(ii => ii.amountRatioInTheDirectParent < 0.05);
    })
  }

  public get showGroupFunction() {
    const additives = ProductDisplayServiceAdditive.flattenAdditiveList(this.product, this.setting);
    return additives.length !== ProductDisplayServiceAdditive.groupByFunction(additives).length;
  }

  // 原材料全体の5％未満の複合原材料が存在するか
  public get showFewCompositeItemsOmitted() {
    const itemsWithInnerItems = this.getAllNormalizeItemsFlatten()
      .filter(i => !i.ingredient.isPreproduct)
      .filter(item => {
        return ProductDisplayService.excludeFromIngredientNamesRecursive(item.items, this.setting.isWaterOmitted).length > 0;
      });
    return itemsWithInnerItems.some(item => item.concentratedAmountRatioInTotal < 0.05);
  }

  public get canHideColoringPurpose(): boolean {
    const additives = this.product.getAllIngredientAdditivesRecursive();
    const colorings = additives.filter(a => a.additivePurposeSynonym && a.additivePurposeSynonym.additivePurposeId === AdditivePurposeColoring);
    return colorings.every(c => c.displayName.includes('色'));
  }

  public get containsWater():boolean {
    return ProductDisplayService.normalize(this.product, this.setting).some(containsWater);
  }

  public get calcWithReducedWeight():boolean {
    return this.product.getAllChildrenRecursive().some(c => c.shouldYieldAsConcentrationReduction);
  }

  public get hasThickenerAdditive() {
    return this.product.getAllIngredientAdditivesRecursive().some(a => {
      return (a.additivePurposeSynonym && isThickeningStabilizer(a.additivePurposeSynonym.synonym)) &&
        (a.additive && a.additive.canGroupAsPolysaccharideThickener);
    });
  }

  public get hasAnyMadeInArea() {
    return this.madeInAreaService.hasAnyMadeInArea;
  }
  public get showIsMadeInAreaSeparated() {
    return !this.product.isAssort;
  }
  public get showManyMadeInAreasOmitted() {
    return this.madeInAreaService.showManyMadeInAreasOmitted;
  }
  // エリアが2つ以上あるか
  public get showMadeInAreasRatioVariable() {
    return this.madeInAreaService.showMadeInAreasRatioVariable;
  }
  // 海外が3カ国以上あるか
  public get showMadeInAreasManyForeignSummarized() {
    return this.madeInAreaService.showMadeInAreasManyForeignSummarized;
  }
  // 国内が含まれるか
  public get showMadeInAreasDomesticSummarized() {
    return this.madeInAreaService.showMadeInAreasDomesticSummarized;
  }

  public get hasAnyProduct() {
    return this.product.productItems.some(pi => pi.isChildProduct);
  }
}

