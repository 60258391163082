












































































































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {LabelExtraFields} from "@/entities/product-label-entity";

import { help as PopoverText } from '@/lang/help/product-create';
import {ExpirationFormatTypeMap } from "@/entities/specs/spec-entity";
import {
  AmountTypeEnum,
  LabelCategoryTypes,
  LabelPreservationText,
  LabelExpirationTypes,
} from '@/entities/interfaces/i-label-entity';

import {
  LayoutTypeDict,
  LayoutTypeKeys,
  NutritionLayoutTypeDict,
  PriceFormatTypeDict,
  TaxType
} from '@/entities/product-label-layout-entity';

import ProductEntity, {ValidatorRules} from "@/entities/product-entity";

import range from 'lodash/range';
import {LabelSheetEntity} from "@/entities/label-sheet-entity";
import {LabelSheetRepository} from "@/repositories/master/label-sheet-repository";

import BorderedTable from '@/views/label/companies/print/layouts/BorderedTable.vue';
import BorderlessTable from '@/views/label/companies/print/layouts/BorderlessTable.vue';
import InlineDelimitedByBall from '@/views/label/companies/print/layouts/InlineDelimitedByBall.vue';

import {NutritionUnits} from '@/entities/nutrition-entity';
import ProductCommonNameEntity from "@/entities/product-common-name-entity";
import ProductCommonNameRepository from "@/repositories/master/product-common-name-repository";

import NutritionBasicSection from '@/components/Project/nutrition/NutritionBasicSection.vue';
import ProductDisplayServiceMadeInArea from "@/services/product-display-service-made-in-area";
import LabelSettingResponsible from "@/views/label/companies/product/components/LabelSettingResponsible.vue";
import LabelSettingExtraFields from "@/views/label/companies/product/components/LabelSettingExtraFields.vue";
import ProductLabelIdentificationMarkEntity, {
  MarkType,
  MarkTypeDict,
  Materials
} from '@/entities/product-label-identification-mark-entity';
import {PartsNames} from "@/entities/specs/spec-package-material-entity";
import LabelAmountFormSection from "@/components/Project/LabelAmountFormSection.vue";
import CompanyEntity from "@/entities/company-entity";
import LabelNutritionSection from "@/components/Project/nutrition/LabelNutritionSection.vue";

const PreviewSheetIdStoreKey = 'product.create.label-setting.preview-sheet-id';

@Component({
  components: {
    LabelNutritionSection,
    LabelAmountFormSection,
    LabelSettingExtraFields,
    LabelSettingResponsible,
    BorderedTable,
    BorderlessTable,
    InlineDelimitedByBall,
    NutritionBasicSection,
  }
})
export default class extends Vue {
  @Prop({required: true}) company!:CompanyEntity;
  @Prop({required: true}) model!:ProductEntity;
  @Prop({required: true}) ingredientText!:string;

  private previewSheetId:number = 0;
  private labelSheets:LabelSheetEntity[] = [];

  private readonly PopoverText = PopoverText;
  private readonly LabelCategoryTypes = LabelCategoryTypes;
  private readonly LabelPreservationText = LabelPreservationText;
  private readonly LabelExpirationTypes = LabelExpirationTypes;
  private readonly ExpirationFormatTypeMap = ExpirationFormatTypeMap;
  private readonly AmountTypeEnum = AmountTypeEnum;
  private readonly TaxType = TaxType;
  private readonly rules = ValidatorRules;

  private readonly NutritionUnits = NutritionUnits;

  private readonly ExtraFields = LabelExtraFields;

  private readonly PartsNames = PartsNames;
  private readonly LayoutTypeDict = LayoutTypeDict;
  private readonly NutritionLayoutTypeDict = NutritionLayoutTypeDict;
  private readonly PriceFormatTypeDict = PriceFormatTypeDict;
  private readonly fontSizes = range(5.5, 24, 0.5);
  private readonly Materials = Materials;
  private readonly MarkTypeDict = MarkTypeDict;
  private readonly MarkType = MarkType;

  private productCommonNames:ProductCommonNameEntity[] = [];


  private get label() {
    return this.model.productLabel;
  }
  private get labelLayout() {
    return this.model.productLabelLayout;
  }

  private get selectedSheet(): LabelSheetEntity|undefined {
    return this.labelSheets.find(ls => ls.id === this.previewSheetId);
  }
  private get selectedLayout(): string {
    return LayoutTypeKeys[this.labelLayout.layoutType];
  }

  private created(){
    (new LabelSheetRepository()).all().then(res => {
      this.labelSheets = res;
      this.specifyPreviewSheetId();
    });
    (new ProductCommonNameRepository).findAllWithCache().then((data:ProductCommonNameEntity[]) => {
      this.productCommonNames = data;
    });

    if (this.label.identificationMarks.length === 0) {
      this.addMark();
    }
  }
  private specifyPreviewSheetId() {
    if (this.$route.query['sheet_id']) {
      this.previewSheetId = Number(this.$route.query['sheet_id']);
    } else if (localStorage.getItem(PreviewSheetIdStoreKey)) {
      this.previewSheetId = Number(localStorage.getItem(PreviewSheetIdStoreKey));
    } else if (this.labelSheets.length > 0) {
      this.previewSheetId = this.labelSheets[0].id;
    }
  }

  public storeSelectedPreviewSheetId() {
    localStorage.setItem(PreviewSheetIdStoreKey, this.previewSheetId.toString());
  }

  private get showIdentificationMarkMaterialCol(): boolean {
    return this.label.identificationMarks.some(im => im.isMaterialNamesEnabled);
  }
  private addMark() {
    this.label.identificationMarks.push(new ProductLabelIdentificationMarkEntity());
  }
}

