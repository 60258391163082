import RepositoryBase from "@/repositories/repository-base";
import UserEntity from "@/entities/user-entity";

import ProductEntity from "@/entities/product-entity";
import ProductDisplayService  from "@/services/product-display-service";
import {AxiosResponse} from "axios";
import CompanyEntity from "@/entities/company-entity";

enum RowDataType {
  Ingredient = '原材料',
  Additive = '添加物',
}

interface IRowData {
  type: RowDataType,
  name: string;
  amount: number;
  areas: string[];
  allergens: string[];
}

export default class ReportRepository extends RepositoryBase<UserEntity> {
  protected ctor:new(data) => any = Object;

  public constructor(companyId) {
    super();
    this.endpoint = `companies/${companyId}/reports`
  }

  public async downloadDepartmentAllergenReport(products:ProductEntity[], company:CompanyEntity): Promise<AxiosResponse> {
    const data = products.map(product => {
      const service = new ProductDisplayService(product, product.productDisplaySetting, company.setting);
      return {
        name: product.name,
        mainIngredient: service.getMadeInNames('prepend'),
        price: product.price,
        allergens: product.getAllAllergens().map(a => a.id),
      }
    });

    return super.post(`${this.endpoint}/department-allergen`, {
      rows: data
    }, {
      responseType: 'blob'
    });
  }

}
