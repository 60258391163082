







import { Component, Vue, Prop } from 'vue-property-decorator';
import ProductEntity from "@/entities/product-entity";

@Component
export default class extends Vue {
  @Prop({required: true})
  private product!:ProductEntity;
}
