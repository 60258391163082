



























import { Component, Vue, Prop } from 'vue-property-decorator';
import SimpleFormItem from "@/components/Form/SimpleFormItem.vue";
import {ProductForIndex} from "@/repositories/company/product-repository";

@Component({
  components: {SimpleFormItem}
})
export default class extends Vue {
  @Prop({required: true}) private value!:boolean;
  @Prop({required: true}) private selectedRows!:ProductForIndex[];

  private get visible():boolean { return this.value };
  private set visible(val:boolean) { this.$emit('input', val); };

  private get referredItems(): ProductForIndex[] {
    return this.selectedRows.filter(item => item.referredSpecsCount > 0);
  }
  private submit(){
    this.$emit('confirmed');
    this.visible = false;
  }
}
