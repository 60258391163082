





























































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';

import BorderedTable from '@/views/label/companies/print/layouts/BorderedTable.vue';
import BorderlessTable from '@/views/label/companies/print/layouts/BorderlessTable.vue';
import InlineDelimitedByBall from '@/views/label/companies/print/layouts/InlineDelimitedByBall.vue';

import NutritionBasicSection from '@/components/Project/nutrition/NutritionBasicSection.vue';
import LabelSettingResponsible from "@/views/label/companies/product/components/LabelSettingResponsible.vue";
import LabelSettingExtraFields from "@/views/label/companies/product/components/LabelSettingExtraFields.vue";
import LabelAmountFormSection from "@/components/Project/LabelAmountFormSection.vue";
import LabelNutritionSection from "@/components/Project/nutrition/LabelNutritionSection.vue";
import Accordion from "@/components/Accordion.vue";
import ProductDisplayServiceSettingVisibility from "@/services/product-display-service-setting-visibility";
import ProductDisplaySetting from "@/entities/product-display-setting";

import { help as PopoverText } from '@/lang/help/product-create';

@Component({
  components: {
    Accordion,
    LabelNutritionSection,
    LabelAmountFormSection,
    LabelSettingExtraFields,
    LabelSettingResponsible,
    BorderedTable,
    BorderlessTable,
    InlineDelimitedByBall,
    NutritionBasicSection,
  }
})
export default class extends Vue {
  @Prop({required: true}) settingVisibility!:ProductDisplayServiceSettingVisibility;
  @Prop({required: true}) productDisplaySetting!:ProductDisplaySetting;

  private PopoverText = PopoverText;

  @Emit('allergenSummarizedChanged')
  private onAllergenSummarizedChanged(val) {
    return val;
  }
}
