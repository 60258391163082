<template>
  <div :class="baseClass">
    <div :class="[`${baseClass}__trigger`, {'is-open': isOpened}]" @click="isOpened = !isOpened">
      <span v-if="title" :class="[`${baseClass}__title`, {'is-open': isOpened}]">{{title}}</span>
      <slot name="title"></slot>
    </div>
    <transition name="js-accordion" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
      <div :class="[`${baseClass}__target`, { 'is-open': isOpened }]" v-if="isOpened">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: "",
      },
      baseClass:{
        type: String,
        default: 'accordion'
      },
      open: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isOpened: this.open
      };
    },
    methods: {
      beforeEnter: function(el) {
        // el.style.height = '0';
      },
      enter: function(el) {
        // el.style.height = el.scrollHeight + 'px';
      },
      beforeLeave: function(el) {
        // el.style.height = el.scrollHeight + 'px';
      },
      leave: function(el) {
        // el.style.height = '0';
      }
    }
  };
</script>

<style lang="scss">
  .accordion{
    &__title {
      padding: 20px;
    }
    &__trigger{
      position: relative;
      display: block;
      width: 100%;
      transition: all 0.2s ease-in;
      outline: none;
      border: none;
      cursor: pointer;
      
      &:after{
        display: inline-block;
        width: 0;
        height: 0;
        border: solid transparent;
        content: "";
        border-top-color: #5f6569;
        border-width: 7px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1em;
        transition: all 0.2s ease-in;
      }
      &.is-open{
        text-decoration: none;
        &:after{
          transform: translateY(-50%) rotateX(180deg);
          margin-top: -7px;
        }
      }
    }
    
    &__target{
      overflow: hidden;
      transition: 0.4s ease-in-out;
    }
    &-enter-active{
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-name: fade-in;
    }
    &-leave-active{
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-name: fade-in;
    }
  }
</style>
