



































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ProductLabelEntity, { FACTORY_PATTERN_FACTORY_CODES } from "@/entities/product-label-entity";

import { help as PopoverText } from '@/lang/help/product-create';

import { ValidatorRules} from "@/entities/product-entity";

import PartnerFormAddress from "@/components/Project/PartnerFormAddress.vue";

import {
  FactoryType, FactoryTypeDict, PartnerAllType, PartnerAllTypeDict,
  PartnerEntity,
  PartnerType,
  PartnerTypeDict
} from "@/entities/specs/partner-entity";
import PartnerRepository from "@/repositories/spec/company/partner-repository";

import CompanyEntity from "@/entities/company-entity";
import {CompanyRepository} from "@/repositories/company-repository";

class PartnerIdParams {
  public static readonly RESPONSIBLE_COMPANY_ID_SAME_AS_COMPANY = null;
  public static readonly RESPONSIBLE_COMPANY_ID_NEW = -1;
  public static readonly FACTORY_ID_NEW = -1;
}

@Component({
  components: {
    PartnerFormAddress,
  }
})
export default class extends Vue {
  @Prop({required: true}) companyId!:number;
  @Prop({required: true}) label!:ProductLabelEntity;

  private readonly PopoverText = PopoverText;
  private readonly rules = ValidatorRules;

  private readonly PartnerType = PartnerType;
  private readonly PartnerTypeDict = PartnerTypeDict;

  private readonly FactoryType = FactoryType;
  private readonly FACTORY_PATTERN_FACTORY_CODES = FACTORY_PATTERN_FACTORY_CODES;

  private readonly PartnerIdParams = PartnerIdParams;

  private partners:PartnerEntity[]|null = null;
  private company:CompanyEntity|null = null;

  private get initialized() {
    return !!this.partners && !!this.company;
  }

  private async created(){
    await Promise.all([
      (new PartnerRepository(this.companyId)).all().then(data => {
        this.partners = data;
      }),
      (new CompanyRepository).findById(this.companyId).then(data => {
        this.company = data;
      }),
    ]).then(() => {
      this.init();
    });
  }

  @Watch('label')
  private init() {
    if (!this.label.responsibleCompany) {
      this.label.responsibleCompany = new PartnerEntity();
      this.label.responsibleCompany!.import(this.company!);
    }
    if (!this.label.responsibleFactory) {
      this.label.responsibleFactory = new PartnerEntity();
    }
  }

  private getPartners(type:PartnerAllType): PartnerEntity[] {
    return (this.partners || []).filter(p => p.type === type);
  }
  private getPartner(id:number): PartnerEntity|null {
    return this.partners!.find(p => p.id === id) || null;
  }

  private onCompanyTypeChanged(val: PartnerType) {
    this.label.responsibleFactoryPattern = null;

    if (val === PartnerType.Seller) {
      this.label.showFactoryName = true;
    }

    if (this.label.responsibleCompanyId === PartnerIdParams.RESPONSIBLE_COMPANY_ID_SAME_AS_COMPANY) return;
    if (this.label.responsibleCompanyId === PartnerIdParams.RESPONSIBLE_COMPANY_ID_NEW) return;
    if (this.label.responsibleCompanyId > 0) {
      // マスタから選択されている場合、typeが変わると参照できなくなるのでリセットする
      this.label.responsibleCompanyId = PartnerIdParams.RESPONSIBLE_COMPANY_ID_NEW;
    }
  }
  private onResponsibleCompanyChanged(selected) {
    if (selected === this.PartnerIdParams.RESPONSIBLE_COMPANY_ID_NEW) {
      this.label.responsibleCompany = new PartnerEntity();
    } else if (selected === this.PartnerIdParams.RESPONSIBLE_COMPANY_ID_SAME_AS_COMPANY) {
      this.label.responsibleCompany!.import(this.company!);
    } else if (selected > 0) {
      this.label.responsibleCompany = this.getPartner(selected);
    }
  }
  private onFactoryPatternChanged() {
    // マスタから選択されている場合、typeが変わると参照できなくなるのでリセットする
    if (this.label.responsibleFactoryPattern === null) return;
    this.label.responsibleFactoryId = PartnerIdParams.FACTORY_ID_NEW;
  }
  private onFactoryChanged(selected) {
    if (selected === this.PartnerIdParams.FACTORY_ID_NEW) {
      this.label.responsibleFactory = new PartnerEntity();
    } else if (selected > 0) {
      this.label.responsibleFactory = this.getPartner(selected);
    }
  }

  private getFactoryLabel(): string {
    if (this.label.responsibleFactoryPattern === null) return '';
    return FactoryTypeDict[this.label.responsibleFactoryPattern];
  }
  private get showFactory(): boolean {
    if (this.label.responsibleFactoryPattern === null) return false;
    if (this.label.responsibleFactoryPattern === FACTORY_PATTERN_FACTORY_CODES) return false;
    return true;
  }

}

