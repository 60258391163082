
























































































import { Component, Prop, Watch} from 'vue-property-decorator';

import ProductRepository, {ProductForIndex, ProductListType} from "@/repositories/company/product-repository";

import ProductCard from './components/ProductCard.vue';
import InputSearch from '@/views/label/companies/components/InputSearch.vue';

import ProductEntity from "@/entities/product-entity";
import ReportRepository from "@/repositories/company/report-repository";
import LoadingHandler from '@/utils/loading-handler';
import {extractFileNameFromResponseHeader, IFindOption} from "@/repositories/repository-base";
import ListPageBase from "@/views/label/companies/ListPageBase";
import stringifyCsv from "csv-stringify/lib/es5";
import * as FileSaver from "file-saver";
import round from 'lodash/round';
import uniq from 'lodash/uniq';
import ProductDisplayService from "@/services/product-display-service";
import formatDate from "date-fns/format";
import {BOM} from "@/utils/bom-utils";
import DeleteConfirmDialog from "@/views/label/companies/product/components/DeleteConfirmDialog.vue";
import DepartmentAnnotationOnIndex from "@/views/components/Department/DepartmentAnnotationOnIndex.vue";
import CompanyEntity from "@/entities/company-entity";
import EditableSlot from "@/views/components/Department/EditableSlot.vue";
import {CompanyRepository} from "@/repositories/company-repository";

@Component({
  components: {
    EditableSlot,
    DepartmentAnnotationOnIndex,
    DeleteConfirmDialog,
    ProductCard,
    InputSearch
  }
})
export default class extends ListPageBase {
  private readonly MAX_PRINTABLE_COUNT = 5;
  private readonly MAX_PRINTABLE_COUNT_ALLERGEN = 25;

  protected fetchIngredientCount:boolean = true;
  private deletionConfirmationModalVisible = false;

  protected getRepository() {
    return new ProductRepository(this.companyId);
  }

  protected async find(searchText, opt:IFindOption) {
    return await (new ProductRepository(this.companyId)).search(searchText, opt);
  }

  protected async onCreating() {
    this.routeName = 'product';
  }

  private async downloadLabelOrderEvidence() {
    if (this.selectedRows.length > this.MAX_PRINTABLE_COUNT_ALLERGEN) return;

    const findAllPromises = this.selectedRows.map((row:ProductForIndex) => this.getRepository().findById(row.id) as Promise<ProductEntity>);
    const products = await LoadingHandler(() => Promise.all(findAllPromises), 60000, {text: this.$t('ダウンロード中...') });
    LoadingHandler(() => {
      return (new ProductRepository(this.companyId)).downloadLabelOrderEvidence(products, this.company);
    }, 60000, {text: this.$t('ダウンロード中...') });
  }
  private downloadNutritionEvidenceSimple() {
    LoadingHandler(async () => {
      const rowIds = this.selectedRows.map((r:ProductForIndex) => r.id);
      return (new ProductRepository(this.companyId)).downloadNutritionEvidenceSimple(rowIds);
    }, 60000);
  }

  private downloadNutritionEvidenceDetail() {
    LoadingHandler(async () => {
      const rowIds = this.selectedRows.map((r:ProductForIndex) => r.id);
      return (new ProductRepository(this.companyId)).downloadNutritionEvidenceDetail(rowIds);
    }, 60000);
  }

  private downloadAllergenList() {
    LoadingHandler(async () => {
      const rowIds = this.selectedRows.map((r:ProductForIndex) => r.id);
      return (new ProductRepository(this.companyId)).downloadAllergenList(rowIds);
    }, 60000);
  }

  private downloadDepartmentReport() {
    if (this.selectedRows.length > this.MAX_PRINTABLE_COUNT) return;

    const promises = this.selectedRows.map((row:ProductForIndex) => {
      return this.getRepository().findById(row.id!).then(product => {
        return (new ProductRepository(this.companyId)).downloadDepartmentReport(product, this.company!).then(res => {
          return {name: row.name, response: res};
        });
      });
    });

    LoadingHandler(() => Promise.all(promises), 60000, {text: this.$t('ダウンロード中...') })
      .then((files:any) => {
        files.forEach(file => {
          ReportRepository.saveDownloadedBlob(file.response, `${file.name}_百貨店向け原材料等チェックシート`);
        });
      });
  }

  private downloadDepartmentAllergenReport() {
    if (this.selectedRows.length > this.MAX_PRINTABLE_COUNT_ALLERGEN) return;

    const findAllPromises = this.selectedRows.map((row:ProductForIndex) => this.getRepository().findById(row.id) as Promise<ProductEntity>);
    LoadingHandler(() => Promise.all(findAllPromises), 60000, {text: this.$t('ダウンロード中...') }).then((products:any[]) => {
      (new ReportRepository(this.companyId)).downloadDepartmentAllergenReport(products, this.company!).then(res => {
        ReportRepository.saveDownloadedBlob(res, this.$t(`催事アレルゲン表示確認・商品リスト`));
      });
    });
  }

  private exportCsv() {
    const findAllPromises = this.selectedRows.map((row:ProductForIndex) => this.getRepository().findById(row.id) as Promise<ProductEntity>);
    LoadingHandler(() => Promise.all(findAllPromises), 60000, {text: this.$t('ダウンロード中...')}).then((products:ProductEntity[]) => {
      const options = {
        header: true,
        columns: [
          '商品ID', '商品名', '名称', 'アレルゲン',
          '販売価格(円)', '原価(円)', '原価率(%)', '商品重量(g)',
          '原材料名', '添加物名',
          '100gあたり熱量(kcal)', '100gあたりたんぱく質(g)', '100gあたり脂質(g)', '100gあたり炭水化物(g)', '100gあたり食塩相当量(g)',
          '共有メモ', '最終更新日',
        ]
      };
      const rows = products.map((row: ProductEntity) => {
        return [
          row.companyOrd, row.name, row.categoryName, uniq(row.getAllAllergens().map(a => a.getDisplayName())).join('、'),
          row.price, row.costSumComputed, (row.costRatioComputed ? round(row.costRatioComputed * 100, 1) : '-'), row.getAmountSumGram(),
          new ProductDisplayService(row, row.productDisplaySetting, this.company.setting).getIngredientNamesSingle(),
          ProductDisplayService.getAdditiveNames(row, row.productDisplaySetting),
          row.getNutritionPer100g('calorie').formatForRaw(), row.getNutritionPer100g('protein').formatForRaw(),
          row.getNutritionPer100g('lipid').formatForRaw(), row.getNutritionPer100g('carb').formatForRaw(), row.getNutritionPer100g('salt').formatForRaw(),
          row.note, formatDate(row.updatedAt, 'yyyy-MM-dd'),
        ]
      });
      stringifyCsv(rows, options, (err, output) => {
        if (output) {
          FileSaver.saveAs(new Blob([BOM, output], {type: "text/plain;charset=utf-8" } ), "商品一覧.csv");
        }
        if (err) throw err;
      });

    });
  }

  public async onDeleting() {
    const hasReferredSpecs = this.selectedRows.filter((i: ProductForIndex) => i.referredSpecsCount > 0);
    if (hasReferredSpecs.length > 0) {
      this.deletionConfirmationModalVisible = true;
      return;
    } else {
      this.confirmDeleteSelected('id').then(() => {
        this.deleteSelectedProduct();
      });
    }
  }

  private async deleteSelectedProduct() {
    const idList = this.selectedRows.map((i:ProductListType) => i.id);

    // チェックトランザクション
    const latestData = await this.find(this.searchText, this.getSearchOpt());
    if(latestData.data.some(latest =>  {
      if (!idList.includes(latest.id)) return false;
      const target = (this.selectedRows as ProductForIndex[]).find(r => r.id === latest.id)!;
      return (target.referredSpecsCount === 0) !== (latest.referredSpecsCount === 0);
    })) {
      this.$message({type: 'error', message: this.$t('データが更新されています。画面をリロードして再度お試しください。')});
      return;
    }

    // 削除
    await LoadingHandler(async () => {
      await (new ProductRepository(this.companyId)).destroyBulk(idList);
      await this.load();
    });
    this.$message({type: 'info', message: this.$t('削除しました')});
  }

}
