import { render, staticRenderFns } from "./DeleteConfirmDialog.vue?vue&type=template&id=486a778e&scoped=true&"
import script from "./DeleteConfirmDialog.vue?vue&type=script&lang=ts&"
export * from "./DeleteConfirmDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486a778e",
  null
  
)

export default component.exports